export const photos = {
    "pro": [{
		src: require('./assets/Wedding_Photos/0001.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0002.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0003.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0004.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0005.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0006.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0007.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0008.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0009.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0010.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0011.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0012.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0013.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0014.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0015.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0016.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0017.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0018.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0019.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0020.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0021.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0022.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0023.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0024.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0025.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0026.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0027.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0028.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0029.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0030.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0031.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0032.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0033.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0034.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0035.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0036.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0037.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0038.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0039.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0040.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0041.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0042.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0043.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0044.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0045.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0046.jpg'),
		width: 999,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0047.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0048.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0049.jpg'),
		width: 992,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0050.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0051.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0052.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0053.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0054.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0055.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0056.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0057.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0058.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0059.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0060.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0061.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0062.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0063.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0064.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0065.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0066.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0067.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0068.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0069.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0070.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0071.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0072.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0073.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0074.jpg'),
		width: 999,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0075.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0076.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0077.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0078.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0079.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0080.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0081.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0082.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0083.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0084.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0085.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0086.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0087.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0088.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0089.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0090.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0091.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0092.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0093.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0094.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0095.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0096.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0097.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0098.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0099.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0100.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0101.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0102.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0103.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0104.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0105.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0106.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0107.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0108.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0109.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0110.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0111.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0112.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0113.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0114.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0115.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0116.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0117.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0118.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0119.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0120.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0121.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0122.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0123.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0124.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0125.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0126.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0127.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0128.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0129.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0130.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0131.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0132.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0133.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0134.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0135.jpg'),
		width: 1500,
		height: 999,
	},
	{
		src: require('./assets/Wedding_Photos/0136.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0137.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0138.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0139.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0140.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0141.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0142.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0143.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0144.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0145.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0146.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0147.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0148.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0149.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0150.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0151.jpg'),
		width: 1000,
		height: 1500,
	},
	{
		src: require('./assets/Wedding_Photos/0152.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0153.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0154.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0155.jpg'),
		width: 1500,
		height: 999,
	},
	{
		src: require('./assets/Wedding_Photos/0156.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0157.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0158.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0159.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0160.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0161.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0162.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0163.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0164.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0165.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0166.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0167.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0168.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0169.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0170.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0171.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0172.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0173.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0174.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0175.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0176.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0177.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0178.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0179.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0180.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0181.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0182.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0183.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0184.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0185.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0186.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0187.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0188.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0189.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0190.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0191.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0192.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0193.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0194.jpg'),
		width: 1500,
		height: 999,
	},
	{
		src: require('./assets/Wedding_Photos/0195.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0196.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0197.jpg'),
		width: 1500,
		height: 1000,
	},
	{
		src: require('./assets/Wedding_Photos/0198.jpg'),
		width: 1500,
		height: 1000,
	},
],
    "polaroid": [{
		src: require('./assets/Polaroids/Singles/1Polaroid0000.jpg'),
		width: 2603,
		height: 4093,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0003.jpg'),
		width: 2606,
		height: 4078,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0007.jpg'),
		width: 2615,
		height: 4092,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0008.jpg'),
		width: 2598,
		height: 4116,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0009.jpg'),
		width: 2616,
		height: 4098,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0015.jpg'),
		width: 2567,
		height: 4099,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0016.jpg'),
		width: 2616,
		height: 4099,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0018.jpg'),
		width: 2591,
		height: 4093,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0020.jpg'),
		width: 2600,
		height: 4094,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0036.jpg'),
		width: 2615,
		height: 4105,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0042.jpg'),
		width: 2603,
		height: 4100,
	},
	{
		src: require('./assets/Polaroids/Singles/1Polaroid0053.jpg'),
		width: 2608,
		height: 4085,
	},
	{
		src: require('./assets/Polaroids/Singles/2Polaroid0010.jpg'),
		width: 2609,
		height: 4093,
	},
	{
		src: require('./assets/Polaroids/Singles/2Polaroid0028.jpg'),
		width: 2616,
		height: 4093,
	},
	{
		src: require('./assets/Polaroids/Singles/2Polaroid0034.jpg'),
		width: 2615,
		height: 4099,
	},
	{
		src: require('./assets/Polaroids/Singles/2Polaroid0035.jpg'),
		width: 2603,
		height: 4117,
	},
	{
		src: require('./assets/Polaroids/Singles/2Polaroid0038.jpg'),
		width: 2628,
		height: 4117,
	},
	{
		src: require('./assets/Polaroids/Singles/2Polaroid0079.jpg'),
		width: 2597,
		height: 4083,
	},
	{
		src: require('./assets/Polaroids/Singles/2Polaroid0080.jpg'),
		width: 2599,
		height: 4105,
	},
	{
		src: require('./assets/Polaroids/Singles/2Polaroid0081.jpg'),
		width: 2599,
		height: 4117,
	},
	{
		src: require('./assets/Polaroids/Singles/2Polaroid0089.jpg'),
		width: 2617,
		height: 4112,
	},
	{
		src: require('./assets/Polaroids/Singles/3Polaroid0005.jpg'),
		width: 2602,
		height: 4110,
	},
	{
		src: require('./assets/Polaroids/Singles/3Polaroid0026.jpg'),
		width: 2628,
		height: 4117,
	},
	{
		src: require('./assets/Polaroids/Singles/3Polaroid0027.jpg'),
		width: 2615,
		height: 4099,
	},
	{
		src: require('./assets/Polaroids/Singles/3Polaroid0031.jpg'),
		width: 2616,
		height: 4105,
	},
	{
		src: require('./assets/Polaroids/Singles/3Polaroid0051.jpg'),
		width: 2575,
		height: 4128,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0001.jpg'),
		width: 2598,
		height: 4088,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0002.jpg'),
		width: 2595,
		height: 4094,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0006.jpg'),
		width: 2580,
		height: 4087,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0014.jpg'),
		width: 2603,
		height: 4116,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0017.jpg'),
		width: 2609,
		height: 4111,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0019.jpg'),
		width: 2648,
		height: 4100,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0021.jpg'),
		width: 2630,
		height: 4106,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0023.jpg'),
		width: 2602,
		height: 4065,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0024.jpg'),
		width: 2607,
		height: 4100,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0025.jpg'),
		width: 2633,
		height: 4093,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0037.jpg'),
		width: 2633,
		height: 4099,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0039.jpg'),
		width: 2603,
		height: 4099,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0040.jpg'),
		width: 2615,
		height: 4087,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0044.jpg'),
		width: 2592,
		height: 4093,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0046.jpg'),
		width: 2592,
		height: 4119,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0047.jpg'),
		width: 2617,
		height: 4101,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0048.jpg'),
		width: 2602,
		height: 4112,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0049.jpg'),
		width: 2572,
		height: 4114,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0050.jpg'),
		width: 2610,
		height: 4120,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0052.jpg'),
		width: 4104,
		height: 2626,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0054.jpg'),
		width: 2621,
		height: 4112,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0056.jpg'),
		width: 2612,
		height: 4099,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0057.jpg'),
		width: 2616,
		height: 4118,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0058.jpg'),
		width: 2599,
		height: 4082,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0059.jpg'),
		width: 2617,
		height: 4113,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0062.jpg'),
		width: 2612,
		height: 4095,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0063.jpg'),
		width: 2629,
		height: 4104,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0067.jpg'),
		width: 2617,
		height: 4085,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0068.jpg'),
		width: 2617,
		height: 4101,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0069.jpg'),
		width: 2605,
		height: 4101,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0070.jpg'),
		width: 2587,
		height: 4088,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0071.jpg'),
		width: 2623,
		height: 4089,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0072.jpg'),
		width: 2611,
		height: 4101,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0073.jpg'),
		width: 2593,
		height: 4107,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0075.jpg'),
		width: 2593,
		height: 4095,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0077.jpg'),
		width: 2599,
		height: 4113,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0078.jpg'),
		width: 2618,
		height: 4095,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0082.jpg'),
		width: 2608,
		height: 4099,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0083.jpg'),
		width: 2587,
		height: 4083,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0084.jpg'),
		width: 2611,
		height: 4106,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0085.jpg'),
		width: 2617,
		height: 4113,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0086.jpg'),
		width: 2581,
		height: 4107,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0087.jpg'),
		width: 2599,
		height: 4100,
	},
	{
		src: require('./assets/Polaroids/Singles/4Polaroid0088.jpg'),
		width: 2600,
		height: 4112,
	},
	{
		src: require('./assets/Polaroids/Singles/5Polaroid0060.jpg'),
		width: 2598,
		height: 4101,
	},
	{
		src: require('./assets/Polaroids/Singles/5Polaroid0064.jpg'),
		width: 2581,
		height: 4095,
	},
	{
		src: require('./assets/Polaroids/Singles/5Polaroid0065.jpg'),
		width: 2593,
		height: 4113,
	},
	{
		src: require('./assets/Polaroids/Singles/5Polaroid0074.jpg'),
		width: 2604,
		height: 4106,
	},
	{
		src: require('./assets/Polaroids/Singles/6Polaroid0004.jpg'),
		width: 2612,
		height: 4089,
	},
	{
		src: require('./assets/Polaroids/Singles/6Polaroid0012.jpg'),
		width: 4090,
		height: 2591,
	},
	{
		src: require('./assets/Polaroids/Singles/6Polaroid0013.jpg'),
		width: 4074,
		height: 2610,
	},
	{
		src: require('./assets/Polaroids/Singles/6Polaroid0029.jpg'),
		width: 4081,
		height: 2615,
	},
	{
		src: require('./assets/Polaroids/Singles/6Polaroid0032.jpg'),
		width: 2603,
		height: 4105,
	},
	{
		src: require('./assets/Polaroids/Singles/6Polaroid0033.jpg'),
		width: 2633,
		height: 4105,
	},
	{
		src: require('./assets/Polaroids/Singles/6Polaroid0041.jpg'),
		width: 2597,
		height: 4099,
	},
	{
		src: require('./assets/Polaroids/Singles/6Polaroid0043.jpg'),
		width: 2622,
		height: 4105,
	},
	{
		src: require('./assets/Polaroids/Singles/6Polaroid0045.jpg'),
		width: 2621,
		height: 4105,
	},
	{
		src: require('./assets/Polaroids/Singles/6Polaroid0066.jpg'),
		width: 2598,
		height: 4093,
	},
]
   }
